import React from "react";

function Footer(props) {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-content">
          <p>© 2021 Common Cents Financial Wellness</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
