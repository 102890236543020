import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "./../views/Header";
import Mission from "./../views/Mission";
import Footer from "./../components/Footer";
import Youtube from "./../views/Youtube";
import Budget from "../views/Budget";
import Logo from "../assets/img/CCFW-Round-Dark.png";
import { Link } from "react-router-dom";

function Home(props) {
  return (
    <div className="home">
      <Link to="/">
        <img className="logo" src={Logo} alt="CCFW Logo" />
      </Link>
      <Sidebar />
      <div className="content">
        <Header />
        <Youtube />
        <Mission />
        <Budget />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
