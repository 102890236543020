import React, { useState } from "react";

import { Email, Facebook, Linkedin, Phone } from "./icons";
import { NavLink } from "react-router-dom";

function Sidebar(props) {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const renderLinks = () => {
    return (
      <>
        <a href="https://www.facebook.com/caedenjgoering" className="icon-link">
          <Facebook className="icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/caedengoering"
          className="icon-link"
        >
          <Linkedin className="icon" />
        </a>
        {/* <a
          href="https://www.instagram.com/commoncentsfinancialwellness/"
          className="icon-link"
        >
          <Instagram className="icon" />
        </a> */}
        {/* <a href="https://www.twitter.com/commoncentsfw" className="icon-link">
          <Twitter className="icon" />
        </a> */}
        <a href="#" className="icon-link">
          <Email
            className="icon"
            onClick={() => window.open("mailto:caeden@commoncentsfw.com")}
          />
        </a>
        <a href="tel:267-225-2708" className="icon-link phone-icon">
          <Phone className="icon" />
        </a>
      </>
    );
  };

  return (
    <>
      <div className="sidebar">
        <div
          className={`menu-icon ${drawerOpened ? "opened" : ""}`}
          onClick={() => setDrawerOpened(!drawerOpened)}
        >
          <div className="line-1" />
          <div className="line-2" />
          <div className="line-3" />
        </div>
        {renderLinks()}
      </div>
      <div className={`drawer ${drawerOpened ? "opened" : ""}`}>
        <div className="nav-container">
          <h2>NAVIGATION</h2>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          {/* <div className="nav-item">
            <HomeIcon className="nav-icon" />
          </div>
          <div className="nav-item">
            <BudgetIcon className="nav-icon" />
          </div> */}
          <div className="socials">{renderLinks()}</div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
