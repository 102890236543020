import React from "react";

function Mission(props) {
  return (
    <div className="mission container">
      <div className="mission-container">
        <h2>About</h2>
        <p>
          We all want to create a rich life, but to me, being truly wealthy
          means much more than the numbers in your bank account. To me, it means
          a healthy lifestyle, great relationships, and living with abundance.
        </p>
        <br />
        <p>
          Welcome to the Common Cents Financial Wellness community, where
          together we will educate, inspire, and implement ideas that will make
          us rich in every area of our lives. Becoming wealthy means more than
          financial upside, and it doesn't have to be complicated. It comes down
          to a lot of "common cents" wisdom. By taking small actions every day
          to invest in ourselves and our communities, we can live lives that are
          truly rich.
        </p>
      </div>
    </div>
  );
}

export default Mission;
