import React from "react";
import NextIcon from "./icons/Next";

function ActionButton({
  className,
  label,
  onClick = () => true,
  ...otherProps
}) {
  return (
    <div
      className={`action-button ${className}`}
      onClick={onClick}
      {...otherProps}
    >
      {label}
      <NextIcon className="next-icon" />
    </div>
  );
}

export default ActionButton;
