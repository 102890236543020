import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { initializeApp } from "firebase/app";
import "./assets/scss/styles.scss";
import Home from "./layouts/Home";
import Tools from "./layouts/Tools";
import ScrollToTop from "./hooks/ScrollToTop";

import config from "./dbconfig";
import ContactPage from "./layouts/ContactPage";

function App() {
  useEffect(() => {
    initializeApp(config);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/tools" component={Tools} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
