import React, { useState } from "react";
import { Form, FormField, SubmitButton } from "./../components/forms";
import * as Yup from "yup";
import { getDatabase, ref, push } from "firebase/database";
import ActionButton from "../components/ActionButton";

import ActivityIndicator from "./../components/ActivityIndicator";
import { Formik } from "formik";
//import SuccessCard from "./../components/SuccessCard";

const schema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label("Full name")
    .trim()
    .matches(/^(.*\s+.*)+$/, "Must enter first and last name"),
  email: Yup.string().required().email().label("Email"),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid."
    )
    .optional(),
  subject: Yup.string().required().label("Subject"),
  message: Yup.string().required().label("Message"),
});

function Contact({ aref }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (
    { name, email, phone, subject, message },
    { resetForm }
  ) => {
    setLoading(true);
    const db = getDatabase();
    push(ref(db, "contactForm/"), {
      name,
      email,
      phone,
      subject,
      message,
    })
      .then(() => {
        resetForm();
        window.alert("Message sent!");
      })
      .catch(() => window.alert("Something went wrong. Please try again."));
    setLoading(false);
  };

  return (
    <div className="contact" ref={aref}>
      <div className="container-sm">
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ handleSubmit }) => (
            <div className="contact-content">
              <h2>Contact</h2>
              <p>Want to stay in touch? Shoot me a message!</p>
              <FormField label="Name*" name="name" />
              <FormField label="Email*" name="email" />
              <FormField label="Phone" name="phone" optional />
              <FormField label="Subject*" name="subject" />
              <FormField type="textarea" label="Message*" name="message" />
              <p className="help-text">* - required</p>
              <div className="btn-container">
                <ActionButton label="Send Message" onClick={handleSubmit} />
              </div>
            </div>
          )}
        </Formik>
      </div>
      <ActivityIndicator visible={loading} />
      {/* <SuccessCard visible={visible} setVisible={setVisible} /> */}
    </div>
  );
}

export default Contact;
