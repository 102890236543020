import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { getDatabase, ref, push } from "firebase/database";

import FormField from "./../components/forms/FormField";
import SubmitButton from "./../components/forms/SubmitButton";

const schema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

const Budget = (props) => {
  const handleSubmit = ({ email, name }, { resetForm }) => {
    const db = getDatabase();
    push(ref(db, "budgetSignups/"), {
      email,
      name,
    })
      .then(() => {
        window.open(
          "https://docs.google.com/spreadsheets/d/1G7jdPSc9VQdzTXakX5MtAjDRMsRe2tQxLhfp8suzGP8/edit?usp=sharing"
        );
        resetForm();
      })
      .catch(() => window.alert("Something went wrong. Please try again."));
  };

  return (
    <div className="budget container">
      <div className="content details-container">
        <h2>Budgeting Tools</h2>
        <p>
          Want to get a FREE budget template? Enter your email and access one
          instantly!
        </p>
        <Formik
          initialValues={{ name: "", email: "" }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {() => (
            <>
              <FormField name="name" label="Name" />
              <FormField name="email" label="Email" />
              <div className="button-container">
                <SubmitButton label="Get Budgeting!" />
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Budget;
