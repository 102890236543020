import * as React from "react";

function Blob(props) {
  return (
    <svg
      className="blob"
      viewBox="0 0 516 678"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M160 54C82.582 98.609 47.83 148.955 17.5 233c-32.147 89.079-9.5 219.5 0 242.5S175.29 704.174 310 674c136.792-30.641 151.69-174.215 177.5-312 23.004-122.801 62.751-240.193-35-318C363.075-27.18 259.031-3.063 160 54z"
        fill="#6cc983"
        fillOpacity={0.15}
      />
    </svg>
  );
}

export default Blob;
