import React from "react";

const Youtube = () => {
  return (
    <div className="youtube container">
      <h2>Check out my latest content!</h2>
      <div className="video-container">
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/gEtnZt2lpzU"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

export default Youtube;
