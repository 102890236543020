import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Budget from "./../views/Budget";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";

function Tools(props) {
  return (
    <div className="home tools">
      <Sidebar />
      <Switch>
        <Route path="/tools/budget" component={Budget} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </div>
  );
}

export default Tools;
