import React from "react";
import { useHistory } from "react-router-dom";

import Caeden from "../assets/img/caeden.jpg";
import Blob from "../components/svg/blob.jsx";
import ActionButton from "./../components/ActionButton";

function Header() {
  const history = useHistory();

  return (
    <div className="header">
      <div className="container">
        <div className="split">
          <div className="image-container">
            <img
              className="main-img"
              src={Caeden}
              alt="Image of Caeden pointing at the camera"
            />
            <Blob />
            {/* <svg
              className="blob"
              id="organic-blob"
              width="600"
              height="600"
              xmlns="http://www.w3.org/2000/svg"
              filter="url(#goo)"
            >
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="10"
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                    result="goo"
                  />
                  <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
              </defs>
              <circle id="Circle1"></circle>
              <circle id="Circle2"></circle>
              <circle id="Circle3"></circle>
              <circle id="Circle4"></circle>
            </svg> */}
          </div>
          <div className="header-content">
            <h1>Common Cents Caeden</h1>
            <p>Creating financial tailwind to accelerate your wealth</p>
            <div className="btn-container">
              <ActionButton
                tabIndex={1}
                label="Let’s get connected!"
                onClick={() => history.push("/contact")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
