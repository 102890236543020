import React from "react";

function Button({ className, label, onClick = () => true, ...otherProps }) {
  return (
    <div className={`button ${className}`} onClick={onClick} {...otherProps}>
      {label}
    </div>
  );
}

export default Button;
