import React from "react";
import Footer from "./../components/Footer";
import Sidebar from "../components/Sidebar";
import Contact from "../views/Contact";
import { Link } from "react-router-dom";
import Logo from "../assets/img/CCFW-Round-Dark.png";

function ContactPage(props) {
  return (
    <div className="home">
      <Link to="/">
        <img className="logo" src={Logo} alt="CCFW Logo" />
      </Link>
      <Sidebar />
      <div className="content">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
